import { DxPopupComponent } from "devextreme-angular";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  selector: "app-popup-info",
  templateUrl: "./popup-info.component.html",
  styleUrls: ["./popup-info.component.scss"],
})
export class PopupInfoComponent {
  public text: string = this.translate.instant("WouldYouLikeToLeave");
  public container: ViewContainerRef;
  public parentContainer: ViewContainerRef;
  public parentPopup: DxPopupComponent;
  public isEditReportPopupInfo: boolean = false;

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(public translate: TranslateService) { }

  public closeInfoPopup(): void {
    this.popup.instance.hide().then(() => this.container.clear());
  }

  public closeAllPopups(): void {
    const infoHide: Promise<boolean> = this.popup.instance.hide();
    const mainHide: Promise<boolean> = this.parentPopup.instance.hide();
    Promise.all([infoHide, mainHide]).then(() => {
      this.container.clear();
      this.parentContainer.clear();
    });
  }
}
