import { Injectable } from '@angular/core';
import { EntityType } from '../Models/entityType';

import { IconModel } from '../Models/icon-model';
import { ShareService } from './share.service';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  public dropDownbuttonIconOptions: Array<{ id: number, icon: string }> = [];
  private entityTypeIcons: Map<string, string> = new Map();
  private defaultIconEntityType: IconModel;
  public noIcon: string;

  constructor(private _shareService: ShareService) {
    this.noIcon = "fa-square";
    this.entityTypeIcons.set("Home", "far fa-home fw-pro");
    this.dropDownbuttonIconOptions.push(
      { id: 1, icon: "fa-square" },
      { id: 2, icon: "fas fa-question" },
      { id: 3, icon: "fas fa-map-marker-alt fw-pro" },
      { id: 4, icon: "far fa-city fw-pro" },
      { id: 5, icon: "far fa-home fw-pro" },
      { id: 6, icon: "far fa-university fw-pro" },
      { id: 7, icon: "far fa-building fw-pro" },
      { id: 8, icon: "fa-duotone fa-line-columns fw-pro" },
      { id: 9, icon: "fas fa-grip-lines" },
      { id: 10, icon: "fas fa-cube" },
      { id: 11, icon: "fa fa-cubes" },
      { id: 12, icon: "fas fa-school" },
      { id: 13, icon: "fa fa-flag-o" },
      { id: 14, icon: "fa fa-flag-checkered" },
      { id: 15, icon: "fa fa-road" },
      { id: 16, icon: "fa fa-bars" },
      { id: 17, icon: "fa fa-map" },
      { id: 18, icon: "fa fa-map-o" },
      { id: 19, icon: "fa fa-picture-o" },
      { id: 20, icon: "fa fa-industry" },
      { id: 21, icon: "fa fa-location-arrow" },
      { id: 22, icon: "fa fa-globe" },
      { id: 23, icon: "fa fa-object-group" },
      { id: 24, icon: "fa fa-object-ungroup" },
      { id: 25, icon: "fa fa-map-pin" },
      { id: 26, icon: "fa fa-graduation-cap" },
      { id: 27, icon: "fa fa-plane" },
      { id: 28, icon: "fa fa-bed" },
      { id: 29, icon: "fa fa-train" }

    );
    this.defaultIconEntityType = { id: 1, icon: "fas fa-question" };
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this._shareService.onEntityTypeIconChanged.subscribe(
      (entityType) => {
        this.setIconToEntityType(entityType.Name, entityType.IconName);
      }
    );
  }

  public createIconSupport(entityTypes: EntityType[]): void {
    entityTypes.forEach((entityType) => {
      this.entityTypeIcons.set(entityType.Name, entityType.IconName);
    })
  }

  public getIconByType(entityType: string): string {
    return this.entityTypeIcons.get(entityType);
  }

  public getDefaultIconEntityType(): IconModel {
    return this.defaultIconEntityType;
  }
  public setIconToEntityType(entityType: string, icon: string): void {
    this.entityTypeIcons.set(entityType, icon);
  }

}
