import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogLevel } from 'src/app/Models/enums/log-level';
import { NotifierDuration } from 'src/app/Models/enums/notifier-duration';
import { NotifierType } from 'src/app/Models/enums/notifier-type';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../utility/notify.service';
import { CustomMessageService } from './custom-message.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  notifierDuration: NotifierDuration = NotifierDuration.MEDIUM;
  logLevel: LogLevel;

  constructor(public notifierService: NotifyService,
    public customMessageService: CustomMessageService) {
    if (environment.production) {
      this.logLevel = LogLevel.PRODUCTION;
    } else {
      this.logLevel = LogLevel.DEVELOPMENT;
    }
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          /// If error doesn't have a status then it cannot and should not be printed since "errors" like this happen during routing operations from angular.
          /// Usually error handlers or visual studio ignores them but our implementation of error handling catches every single error.
          if (error.status) {
            this.notifierService.displayNotifyMessage(this.customMessageService.computeErrorMessage(error, this.logLevel), this.notifierDuration,
              NotifierType.ERROR);
            return throwError(error);
          }
        })
      );
  }
}
