import { Store } from "src/app/DATA/store";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "propertyTranslate",
})
export class PropertyPipe implements PipeTransform {
  constructor(private _store: Store) {}

  transform(propertyName: string): string {
    if (!this._store.entityProperties) {
      return propertyName;
    }

    const translatedProperty = this._store.entityProperties.find(
      (prop) => prop.Name === propertyName
    )?.Description;

    if (translatedProperty && localStorage.getItem("locale") == "nl") {
      return translatedProperty;
    }

    return propertyName;
  }
}
