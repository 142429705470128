import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { LogLevel } from 'src/app/Models/enums/log-level';
import { NotifierDuration } from 'src/app/Models/enums/notifier-duration';
import { NotifierType } from 'src/app/Models/enums/notifier-type';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../utility/notify.service';
import { CustomMessageService } from './custom-message.service';

@Injectable({
  providedIn: 'root'
})
export class UiExceptionHandlerService implements ErrorHandler {

  ignoredException: string = "priority-nav";
  logLevel: LogLevel;
  notifierDuration: NotifierDuration = NotifierDuration.MEDIUM;

  constructor(public notifierService: NotifyService,
    public customErrorService: CustomMessageService) {
    if (environment.production) {
      /// For UI errors we cannot specify anything in particular so we stay with the simple errors.
      this.logLevel = LogLevel.SIMPLE;
    } else {
      this.logLevel = LogLevel.DEVELOPMENT;
    }
  }

  handleError(error: any): void {
    /// Known issue by angular, remove this line when will be solved by them.
    /// In development mode we display them regardless.
    if (environment.production && error.stack.includes(this.ignoredException)) {
      return;
    }

    /// Http error responses are handled in a different class no point in handling them here too.
    /// If error doesn't have a status then it cannot and should not be printed since "errors" like this happen during routing operations from angular.
    /// Usually error handlers or visual studio ignores them but our implementation of error handling catches every single error.
    if (error.error instanceof HttpErrorResponse == false && error.status) {
      this.notifierService.displayNotifyMessage(this.customErrorService.computeErrorMessage(error, this.logLevel), this.notifierDuration, NotifierType.ERROR);
    }
  }


}
