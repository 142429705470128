import { Entity } from "src/app/Models/entity";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Scenario } from "../Models/scenario";

@Injectable({
  providedIn: "root",
})
export class ScenarioService {
  constructor(private http: HttpClient) {}

  public postScenario(scenario: Scenario) {
    const value = [];
    value.push(scenario);
    let url = environment.backendControllerUrl + "/Scenario/PostScenarios";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(url, value, { headers });
  }

  public getScenarios(): Observable<Scenario[]> {
    return this.http.get<Scenario[]>(
      environment.backendControllerUrl + "/Scenario/GetScenarios"
    );
  }

  public deleteScenarios(scenarioIds: number[]): Observable<any> {
    return this.http.post(
      environment.backendControllerUrl + "/Scenario/DeleteScenarios",
      scenarioIds
    );
  }

  public getScenarioDrawings(scenarioId: number): Observable<string[]> {
    return this.http
      .post(
        environment.backendControllerUrl + "/Scenario/GetScenarioDrawings",
        scenarioId
      )
      .pipe(
        map((entities: Entity[]) => {
          const drawingNames: string[] = [];
          entities.forEach((entity) => {
            if (
              entity.Details.hasOwnProperty("FileName") ||
              entity.Details["FileName"] != ""
            )
              drawingNames.push((entity.Details as any).FileName);
          });
          return drawingNames;
        })
      );
  }
}
