import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ReportService } from './report.service';
import { environment } from 'src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { ShareService } from './share.service';

@Injectable({
  providedIn: 'root'
})
export class LockingElementService {
  private _lockedElements: Set<string> = new Set();
  private _lockedElementsSubject: Subject<Set<string>> = new Subject<Set<string>>();

  private _storedLockedElements: Set<string> = new Set();

  constructor(private _reportService: ReportService,
    private _share: ShareService) { }

  /// Method used to lock an element based on the id.
  public lockElement(elementId: string): void {
    this._reportService.lockElement(elementId).subscribe(() => {
      this._lockedElementsSubject.next(this._lockedElements);
    });
  }

  /// Method used to unlock an element based on the id.
  public unlockElement(elementId: string): void {
    this._reportService.unlockElement(elementId).subscribe(() => {
      this._lockedElementsSubject.next(this._lockedElements);
    });
  }

  /// Special case where the emitting user may lock his own element.
  /// If this hasppens we only need to unlock the element and enable buttons no need for other complicated operations.
  public unlockElementOnCurrentUser(elementId: string): void {
    this._reportService.unlockElement(elementId).subscribe(() => {
      this._share.emitCurrentlyUnlockedElementFinished(elementId);
    });
  }

  /// Loads the locked elements from the server.
  public loadLockedElements(): Observable<void> {
    return this._reportService.getLockedElements(environment.customer).pipe(
      map((res) => {
        this._storedLockedElements = new Set(res)
      }),
      switchMap(() => of<void>(undefined))
    );
  }

  // Method that checks whether the element with the id elementId is locked or not.
  public isElementLocked(elementId: string): boolean {
    return this._storedLockedElements.has(elementId);
  }

  public getDataSetObservable(): Subject<Set<string>> {
    return this._lockedElementsSubject;
  }
}
