import { Injectable } from '@angular/core';
import { NotifyService } from './notify.service';
import { NotifierDuration } from 'src/app/Models/enums/notifier-duration';
import { NotifierType } from 'src/app/Models/enums/notifier-type';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifyRefreshReportsService {
  private _notifierDuration: NotifierDuration = NotifierDuration.LONG;
  private _notifierType: NotifierType = NotifierType.INFO;
  private _notifierChanged: Subject<void> = new Subject<void>();

  constructor(private _notifyService: NotifyService) { }

  /// Displays the refreshing message in the bottom right corner.
  displayRefreshingMessage(message: string) {
    this._notifyService.displayNotifyMessage(message, this._notifierDuration,
      this._notifierType);
  }

  displayReportsMessage(message: string) {
    this._notifyService.displayNotifyMessage(message, this._notifierDuration, this._notifierType);
  }

  /// Setter for notifier duration.
  setNotifierDuration(notifierDutation: NotifierDuration): void {
    this._notifierDuration = notifierDutation;
  }

  /// Setter for notifier type.
  setNotifierType(notifierType: NotifierType): void {
    this._notifierType = notifierType;
  }

  getNotifierSubject(): Subject<void> {
    return this._notifierChanged;
  }
}
