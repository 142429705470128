import { Directive, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: "[integerNumberFormatValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IntegerNumberFormatValidatorDirective,
      multi: true,
    },
  ],
})
export class IntegerNumberFormatValidatorDirective implements Validator {

  validate(formControl: FormControl) {
    const regex = new RegExp(/^-?\d+$/);

    if (formControl.value === null || formControl.value === "" || formControl.value == undefined) {
      return null;
    }

    if (!regex.test(formControl.value)) {
      return { badFormat: true };
    }

    return null;
  }
}

