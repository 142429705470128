import { Directive, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: "[uniqueEntity]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UniqueEntityValidatorDirective,
      multi: true,
    },
  ],
})
export class UniqueEntityValidatorDirective implements Validator {
  @Input() entityNames: any[];
  @Input() initialName: string;
  @Input() entityType: string;

  validate(formControl: FormControl) {
    let isValueUnique: boolean;
    if (this.initialName && this.entityNames) {
      const filtredData = this.entityNames.filter(
        (entity) => entity.name !== this.initialName
      );
      isValueUnique = !filtredData.find((data) => data.name === formControl.value && data.type === this.entityType );
    } else {
      isValueUnique = ! this.entityNames?.find((entity) => 
       entity.name === formControl.value && entity.type === this.entityType
      );         
    }

    if (!isValueUnique) return { isNotUnique: true };

    return null;
  }
}
