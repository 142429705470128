// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendControllerUrl:
    window.location.origin +
    "/" +
    window.location.pathname.split("/")[1] +
    "/api",
  customer: window.location.pathname.split("/")[1],
  translations: {
    prefix: "https://pptest.azureedge.net/cdn-test-container/space/",
    suffix:
      ".json?sv=2020-08-04&ss=b&srt=co&sp=rx&se=2025-08-13T14:44:13Z&st=2021-08-13T06:44:13Z&spr=https,http&sig=LGG4txlaOxXq5i2eSOUoBhNybQ0JRHxAe%2F%2Bgik02gXs%3D",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
