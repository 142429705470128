import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[hideDropdown]",
})
export class HideDropdownDirective {
  constructor(private _elementRef: ElementRef) {}

  @HostListener("document:click", ["$event", "$event.target"])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside =
      targetElement.className === "chevron-icon fas fa-chevron-down" ||
      targetElement.parentElement?.className === "user-info-container" ||
      targetElement.className === "user-info-container";

    let dropdown = document.getElementsByClassName(
      "dropdown-container"
    )[0] as HTMLElement;

    if (clickedInside) {
      if (dropdown.style.display != "block") {
        dropdown.style.display = "block";
      } else {
        dropdown.style.display = "none";
      }
    } else {
      dropdown.style.display = "none";
    }
  }
}
