import { Store } from "../../DATA/store";
import { Entity } from "../../Models/entity";
import { ShareService } from "../../Services/share.service";
import { Observable } from "rxjs";
import { DataHandlerService } from "src/app/Services/data-handler.service";
import { Component, ViewContainerRef } from "@angular/core";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EntityInfo } from "../../Models/entityInfo";

@Component({
  selector: "app-open-close-location-popup",
  templateUrl: "./open-close-location-popup.component.html",
  styleUrls: ["./open-close-location-popup.component.scss"],
})
export class OpenCloseLocationPopupComponent {
  public isVisible: boolean = false;
  public container: ViewContainerRef;
  public openMode: boolean;
  public entities: Entity[];
  public closedEntities;
  public disableSaveButton: boolean = false;

  constructor(
    private _dataHandler: DataHandlerService,
    private _share: ShareService,
    private _store: Store,
    private _translate: TranslateService
  ) {}

  public hide(): void {
    this.isVisible = false;
    this.disableSaveButton = false;
    this.container.clear();
  }

  public confirm(): void {
    this.disableSaveButton = true;
    let openCloseEntity$: Observable<void>;

    //get filtered data by openMode flag
    let openOrClosedEntities = this.getOpenOrClosedEntities(this.openMode);

    //extract details like id and name
    let openOrClosedEntitiesInfo = this.getEntitisInfo(openOrClosedEntities);

    if (openOrClosedEntities) {
      this.openMode ? openCloseEntity$ = this._dataHandler.openEntity(openOrClosedEntitiesInfo)
                    : openCloseEntity$ = this._dataHandler.closeEntity(openOrClosedEntitiesInfo);
       
      openCloseEntity$.pipe(take(1)).subscribe(() => {
        this._share.onEntityOpenedOrClosed.emit();
        this.hide();
      });
    }
    else {
      this.hide();
    }
  }

  /**
   *  Function will return the desired open or closed filtered entities
   *  @param openMode boolean : Used to return 
   * */
  public getOpenOrClosedEntities(openMode: boolean): Entity[] {

    let openOrClosedEntities = this.entities
      .filter((entity) => entity.IsClosed == openMode);
    if (openOrClosedEntities) {
      return openOrClosedEntities;
    }
    return [];
  }

  /**
   * Function to get entities info regardless of the type of the entities if they are closed/open
   * @param openOrClosedEntities Entity[], array of entities from where we extract data
   * Returns: EntityInfo[] array which contains details like entity ID and Name  
   * */
  public getEntitisInfo(openOrClosedEntities: Entity[]): EntityInfo[] {

    let openOrCloseEntitiesInfo: EntityInfo[] = [];
    openOrClosedEntities.forEach((entity) => {
      const defaultProperty = this._store.defaultPropertyByTypeName.get(
        entity.Type
      );

      openOrCloseEntitiesInfo.push({ id: entity.ID, name: entity.Details?.[defaultProperty] } as EntityInfo);
    });

    return openOrCloseEntitiesInfo;
  }

  public getContent(): any {
    if (this.entities.length > 1) {
      const onlyOpenedEntityNames: string[] = [];
      const onlyClosedEntityNames: string[] = [];
      this.entities.forEach((entity) => {
        const defaultProperty = this._store.defaultPropertyByTypeName.get(
          entity.Type
        );
        if (entity.IsClosed) {
          onlyClosedEntityNames.push(entity.Details?.[defaultProperty]);
        } else {
          onlyOpenedEntityNames.push(entity.Details?.[defaultProperty]);
        }
      });
      return this.openMode
        ? this._translate.instant("WouldYouLikeToOpenLocations", {
          items: onlyClosedEntityNames
            .map((item) => (item = `"${item}"`))
            .join(", "),
        })
        : this._translate.instant("WouldYouLikeToCloseLocations", {
          items: onlyOpenedEntityNames
            .map((item) => (item = `"${item}"`))
            .join(", "),
        });
    }
    const entity = this.entities[0];
    const defaultProperty = this._store.defaultPropertyByTypeName.get(
      entity.Type
    );
    return this.openMode
      ? this._translate.instant("WouldYouLikeToOpenLocation", {
        entityType: this._translate.instant(entity.Type),
        name: `"${entity.Details?.[defaultProperty]}"`,
      })
      : this._translate.instant("WouldYouLikeToCloseLocation", {
        entityType: this._translate.instant(entity.Type),
        name: `"${entity.Details?.[defaultProperty]}"`,
      });
  }
}
