import { Quadrant } from "./../Models/quadrant";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "../DATA/store";
import { shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class QuadrantsResolverService implements Resolve<Quadrant[]> {
  constructor(private _http: HttpClient, private _store: Store) { }

  public resolve(): Observable<Quadrant[]> {
    const quadrants$ = this.getQuadrants().pipe(shareReplay());
    quadrants$.subscribe(
      (quadrants: Quadrant[]) => (this._store.quadrants = quadrants)
    );
    return quadrants$;
  }

  public getQuadrants(): Observable<Quadrant[]> {
    return this._http.get<Quadrant[]>(
      environment.backendControllerUrl + "/Quadrant/GetQuadrants"
    );
  }
}
