import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Store } from "../DATA/store";
import { EntityProperty } from "../Models/entityProperty";

@Injectable({
  providedIn: "root",
})
export class EntityPropertiesResolverService
  implements Resolve<EntityProperty[]>
{
  constructor(private _http: HttpClient, private _store: Store) {}

  public resolve(): Observable<EntityProperty[]> {
    const entityProperties$: Observable<EntityProperty[]> =
      this.getProperties().pipe(shareReplay());
    entityProperties$.subscribe(
      (entityProperties: EntityProperty[]) =>
        (this._store.entityProperties = entityProperties)
    );
    return entityProperties$;
  }

  public getProperties(): Observable<EntityProperty[]> {
    return this._http.get<EntityProperty[]>(environment.backendControllerUrl + "/Property/GetProperties", {});
  }
}
