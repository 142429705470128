import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import {
  DxButtonModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxDataGridModule,
  DxPopupModule,
  DxListModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTooltipModule,
  DxTabsModule,
  DxScrollViewModule,
  DxDateBoxModule,
  DxRadioGroupModule,
  DxTreeViewModule,
  DxChartModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxMenuModule,
  DxPivotGridFieldChooserModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxProgressBarModule,
  DxSwitchModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeListModule,
  DxTagBoxModule,
  DxLookupModule,
} from "devextreme-angular";
import { DxoStateStoringModule } from "devextreme-angular/ui/nested";
import { PropertyPipe } from "./Pipes/property.pipe";
import { HttpLoaderFactory } from "./Utility/ngx-translate";
import { ValidatorsModule } from "./validators/validators.module";
import { DatePipe } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [PropertyPipe],
  imports: [
    ReactiveFormsModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    ValidatorsModule,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxListModule,
    DxSelectBoxModule,
    DxTooltipModule,
    DxTabsModule,
    DxDateBoxModule,
    DxTreeViewModule,
    DxTemplateModule,
    FontAwesomeModule,
    DxScrollViewModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxChartModule,
    DxPivotGridModule,
    DxPivotGridFieldChooserModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxToolbarModule,
    DxDrawerModule,
    DxoStateStoringModule,
    DxLoadIndicatorModule,
    DxMenuModule,
    DxDropDownBoxModule,
    DxTreeListModule,
    DxDropDownButtonModule,
    DxTabsModule,
    DxFileUploaderModule,
    DxPopoverModule,
    DxProgressBarModule,
    DxTagBoxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],
  exports: [
    ReactiveFormsModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    ValidatorsModule,
    PropertyPipe,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxListModule,
    DxSelectBoxModule,
    DxTooltipModule,
    DxTabsModule,
    DxDateBoxModule,
    DxTreeViewModule,
    DxTemplateModule,
    FontAwesomeModule,
    DxScrollViewModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxChartModule,
    DxPivotGridModule,
    DxPivotGridFieldChooserModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxLoadPanelModule,
    DxToolbarModule,
    DxDrawerModule,
    DxoStateStoringModule,
    DxLoadIndicatorModule,
    DxMenuModule,
    DxDropDownBoxModule,
    DxTreeListModule,
    DxDropDownButtonModule,
    DxTabsModule,
    DxFileUploaderModule,
    DxPopoverModule,
    DxProgressBarModule,
    DxLookupModule,
    DxTagBoxModule,
    TranslateModule,
  ],
  providers: [DatePipe, PropertyPipe]
})
export class SharedModule {
  constructor(private _translate: TranslateService) {

    const locale = localStorage.getItem("locale");

    let currentLanguage: string = locale == null || locale == "" ? "nl" : locale;

    _translate.use(currentLanguage);
  }
}
