import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[emptyInputValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmptyInputValidatorDirective,
      multi: true,
    },
  ],
})
export class EmptyInputValidatorDirective implements Validator {

  validate(formControl: FormControl) {

    if (formControl.value === null || formControl.value === "" || formControl.value == undefined) {
      return { emptyInput: true };
    }
    return null;
  }
}
