import { shareReplay } from "rxjs/operators";
import { CustomConfig } from "../Models/customConfig";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { Store } from "../DATA/store";

@Injectable({
  providedIn: "root",
})
export class CustomConfigsResolverService implements Resolve<CustomConfig[]> {
  constructor(private _http: HttpClient, private _store: Store) { }

  public resolve(): Observable<CustomConfig[]> {
    const customConfigs$: Observable<CustomConfig[]> =
      this.getCustomConfigs().pipe(shareReplay());
    customConfigs$.subscribe((customConfigs: CustomConfig[]) => {
      this._store.customConfigs = customConfigs;
    });
    return customConfigs$;
  }

  public getCustomConfigs(): Observable<CustomConfig[]> {
    return this._http.get<CustomConfig[]>(
      environment.backendControllerUrl + "/CustomConfig/GetCustomConfigs"
    );
  }
}
