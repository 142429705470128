import { Directive, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: "[unique]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UniqueValidatorDirective,
      multi: true,
    },
  ],
})
export class UniqueValidatorDirective implements Validator {
  @Input() dataToCheckForUniqueName: any[];
  @Input() initialName: string;
  validate(formControl: FormControl) {
    let isValueUnique: boolean;

    if (this.initialName && this.dataToCheckForUniqueName) {
      const filteredData = this.dataToCheckForUniqueName.filter(
        (item) => item.Name !== this.initialName
      );

      isValueUnique = !filteredData.find(
        (item) => item.Name?.toLowerCase() === formControl.value?.toLowerCase()
      );
    } else
      isValueUnique = !this.dataToCheckForUniqueName?.find(
        (item) => item.Name?.toLowerCase() === formControl.value?.toLowerCase()
      );

    if (!isValueUnique) return { isNotUnique: true };

    return null;
  }
}
