import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalNumberFormatValidatorDirective } from './decimal-number-format-validator';
import { SpecialCharactersValidatorDirective } from './special-characters-validator.directive';
import { UniqueEntityTypeValidatorDirective } from './unique-entity-type-validator';
import { UniqueEntityValidatorDirective } from './unique-entity-validator.directive';
import { UniqueValidatorDirective } from './unique-validator.directive';
import { IntegerNumberFormatValidatorDirective } from './integer-number-validator.directive';
import { EmptyInputValidatorDirective } from './empty-input.validator.directive';



@NgModule({
  declarations: [
    SpecialCharactersValidatorDirective,
    UniqueEntityTypeValidatorDirective,
    UniqueEntityValidatorDirective,
    UniqueValidatorDirective,
    DecimalNumberFormatValidatorDirective,
    IntegerNumberFormatValidatorDirective,
    EmptyInputValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SpecialCharactersValidatorDirective,
    UniqueEntityTypeValidatorDirective,
    UniqueEntityValidatorDirective,
    UniqueValidatorDirective,
    DecimalNumberFormatValidatorDirective,
    IntegerNumberFormatValidatorDirective,
    EmptyInputValidatorDirective  ]
})
export class ValidatorsModule { }
