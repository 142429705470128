import { Resolve } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EntityTypeProperty } from "../Models/entityTypeProps";
import { Store } from "../DATA/store";
import { shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EntityTypePropertiesResolverService
  implements Resolve<EntityTypeProperty[]>
{
  constructor(private _http: HttpClient, private _store: Store) {}
  resolve(): Observable<EntityTypeProperty[]> {
    const entityTypeProperties$: Observable<EntityTypeProperty[]> =
      this.getEntityTypeProperties().pipe(shareReplay());
    entityTypeProperties$.subscribe(
      (entityTypeProperties: EntityTypeProperty[]) =>
        (this._store.entityTypeProperties = entityTypeProperties)
    );
    return entityTypeProperties$;
  }

  public getEntityTypeProperties(): Observable<EntityTypeProperty[]> {
    return this._http.get<EntityTypeProperty[]>(
      environment.backendControllerUrl + "/EntityTypeProperty/GetEntityTypeProperties", {});
  }
}
