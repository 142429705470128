import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { EntityType } from "../Models/entityType";
import { Observable } from "rxjs";
import { Store } from "../DATA/store";
import { shareReplay } from "rxjs/operators";
import { IconService } from "../Services/entityType-icons.service";


@Injectable({
  providedIn: "root",
})
export class EntityTypesResolver implements Resolve<EntityType[]> {
  constructor(private _http: HttpClient,
    private _store: Store,
    private _iconService: IconService) { }
  public resolve(): Observable<EntityType[]> {
    const entityTypes$: Observable<EntityType[]> = this.getTypes().pipe(
      shareReplay()
    );
    entityTypes$.subscribe((entityTypes: EntityType[]) => {
      this._store.entityTypes = entityTypes;
      entityTypes.forEach((entityType) => {
        this._store.defaultPropertyByTypeName.set(
          entityType.Name,
          entityType.DefaultProperty.Name
        );
      });
      this._iconService.createIconSupport(entityTypes);
    });
    return entityTypes$;
  }

  public getTypes(): Observable<EntityType[]> {
    return this._http
      .get<EntityType[]>(environment.backendControllerUrl + "/EntityType/GetTypes");

  }
}
