import { ShareService } from "src/app/Services/share.service";
import { GuardsCheckEnd, NavigationStart, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { locale, loadMessages } from "devextreme/localization";
import nlMessages from "devextreme/localization/messages/nl.json";
import { Store } from "./DATA/store";
import { CustomersService } from "./Services/customers.service";
import { ReportSignalRService } from "./Services/signalR/report-signal-r.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  locale: string;

  constructor(
    private router: Router,
    private _share: ShareService,
    private _store: Store,
    private _customerService: CustomersService,
    private _reportSignalRService: ReportSignalRService
  ) {
    this.validateEvent();
    this.locale = this.getLocale();
    locale(this.locale);
    loadMessages(nlMessages);
    this.redirection();
  }
  ngOnInit(): void {
    this._reportSignalRService.startConnection().toPromise().then(() => {
      this._reportSignalRService.subscribeToListeners();
    });
  }

  private redirection(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart && event.url.includes("reportId")) {
        //we store the ID when an link is pressed from an external link, helps when the user is not logged in and for redirection to the report template
        localStorage.setItem("reportToOpen", event.url.split("reportId=")[1].toString());
        this.redirectToReportId(event);
      }
      else if (event instanceof GuardsCheckEnd) {
        this.redirectFromClientUrl(event);
      }
    });
  }

  redirectToReportId(event: NavigationStart) {
    const reportId: number = +event.url.split("reportId=")[1];
    const client: string = event.url.split("/")[1];
    this.router.navigate([`/${client}/reports`]).then(() => {
      this._share.reportIdFromUrl = reportId;
    });
  }

  private redirectFromClientUrl(event: GuardsCheckEnd): void {

    this._customerService.getCustomers().toPromise().then((customersResponse) => {

      const customers = customersResponse.map((item) => `/${item}`);
      const clientUrl = customers.find((item) => item === event.url);
      const reportToOpen = localStorage.getItem("reportToOpen");

      //checking first if the user has view role
      if (clientUrl && this._store.role === "view") {
        //checking if an reportId is present in the url
        if (reportToOpen) {
          this.redirectToReportFromExternalLink(clientUrl, reportToOpen);
        }
        else {
          localStorage.removeItem("reportToOpen")
          this.router.navigate([`${clientUrl}/reports`]);
        }
      }
      else if (clientUrl) {
        if (reportToOpen) {
          this.redirectToReportFromExternalLink(clientUrl, reportToOpen);
        }
        else {
          localStorage.removeItem("reportToOpen")
          this.router.navigate([`${clientUrl}/space-management`]);
        }
      }
    })
  }
  /**
   * Type: Function
   * Functionality : This function helps navigation to report tab if an ID is present in url
   * */
  redirectToReportFromExternalLink(clientUrl: string, reportId: string): void {

    this.router.navigate([`/${clientUrl}/reports`]).then(() => {
      this._share.reportIdFromUrl = + reportId;
      localStorage.removeItem("reportToOpen");
    });
  }

  validateEvent(): void {

    window.addEventListener("message", (event) => {
      try {
        const information = JSON.parse(event.data);
        const language: string = information.language.toLowerCase();
        const localeLanguage: string = localStorage.getItem("locale");

        if (information.asBuiltUrl) {
          this._store.asBuiltUrl = information.asBuiltUrl;
        }

        if (!language.includes(localeLanguage)) {
          localStorage.setItem("locale", language.substr(0, 2));
          document.location.reload();
        }
      } catch (error) {
        return;
      }
    });
  }

  getLocale() {

    const locale = localStorage.getItem("locale");

    //locale can be null or emptyString
    if (locale != null && locale != "")
      return locale;

    //this would be defualt language if locale is not initialized
    localStorage.setItem("locale", "nl");
    return "nl";
  }
}
