// Types of postitions of notify available in devextreme
export enum NotifyPositionType {
  TopLeft = "top left",
  TopCenter = "top center",
  LeftCenter = "left center",
  Center = "center",
  RightCenter = "right center",
  BottomLeft = "bottom left",
  BottomCenter = "bottom center",
  BottomRight = "bottom right"
}
