import { Component, OnInit } from '@angular/core';
import { PopupInfoComponent } from '../popup-info/popup-info.component';
import { TranslateService } from '@ngx-translate/core';
import { ShareService } from 'src/app/Services/share.service';

@Component({
  selector: 'app-entity-popup-info',
  templateUrl: './entity-popup-info.component.html',
  styleUrls: ['./entity-popup-info.component.scss']
})
export class EntityPopupInfoComponent extends PopupInfoComponent implements OnInit {

  constructor(private translateService: TranslateService,
    private shareService: ShareService) { super(translateService); }

  ngOnInit(): void {
  }

  public closeInfoPopup(): void {
    super.closeInfoPopup();
  }

  public closeAllPopups(): void {
    this.shareService.entityPopupClosed();
    super.closeAllPopups();
  }
}
