import { Injectable } from '@angular/core';
import { LogLevel } from 'src/app/Models/enums/log-level';
import { CustomersService } from '../customers.service';

export class CustomMessageServiceMock {
  private defaultErrorMessage: string = "Mocked error message";
  private logLevel: LogLevel = LogLevel.DEVELOPMENT;

  constructor(private customerService: CustomersService) { }

  setLogLevel() {
    // Mocking setLogLevel if needed
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomMessageService {
  private defaultErrorMessage: string = "Something went wrong.";
  private logLevel: LogLevel = LogLevel.DEVELOPMENT;

  constructor(private customerService: CustomersService) {
    this.setLogLevel();
  }

  /// Computes different error messages depending on the log level enum.
  computeErrorMessage(error: any, logLevel: LogLevel): string {
    switch (this.logLevel) {
      case LogLevel.DEVELOPMENT:
        return error.message + "with status: " + error.status;
      case LogLevel.PRODUCTION:
        if (error.url) {
          return "Failure at: " + this.extractEndpointFromUrl(error.url);
        } else {
          return "Failure: " + error.message;
        }
      default:
        return this.defaultErrorMessage;
    }
  }

  /// Extract the endpoint from a url variable.
  extractEndpointFromUrl(url: string): string {
    const parser = document.createElement('a');
    parser.href = url;

    const segments = parser.pathname.split('/').filter(seg => !!seg);
    const endpoint = segments.length > 0 ? segments[segments.length - 1] : '';

    return endpoint;
  }

  /// Method used to set the log level configuration baseed on app settings.
  private setLogLevel(): void {
    this.customerService.getLogLevelSettings().subscribe(
      response => {
        this.logLevel = response.EnumValue;
      },
      error => {
        console.error('Error fetching settings', error);
      }
    )
  }
}
