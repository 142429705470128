import { TranslateService } from "@ngx-translate/core";
import { ScenarioService } from "src/app/Services/scenario.service";
import { ReportService } from "../../Services/report.service";
import { DataHandlerService } from "../../Services/data-handler.service";
import { Component, OnInit } from "@angular/core";
import { ShareService } from "../../Services/share.service";
import { EntityInfo } from "../../Models/entityInfo";
import { ReportSignalRService } from "src/app/Services/signalR/report-signal-r.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-confirm-delete-popup",
  templateUrl: "./confirm-delete-popup.component.html",
  styleUrls: ["./confirm-delete-popup.component.scss"],
})
export class ConfirmDeletePopupComponent implements OnInit {

  private includedDisableDeleteButtonModes: string[] = ["P", "R", "ET"];

  public mode: string;
  public deletePopupVisible: boolean = false;
  public text: string = this.translate.instant("WouldYouLikeToDeleteSelectedItems");
  public drawings: string[];
  public selectedItemsNames: string[];
  public selectedIds: number[];
  public isButtonDisabled: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public closingOnOutsideClick: boolean = true;
  public showCloseButton: boolean = true;
  public entitiesInfo: EntityInfo[];

  constructor(
    private dataHandler: DataHandlerService,
    private reportService: ReportService,
    private scenarioServise: ScenarioService,
    private share: ShareService,
    private reportSignalRService: ReportSignalRService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToReportEvents();
  }

  public closeDeletePopup(): void {
    this.deletePopupVisible = false;
  }

  public closeAndDelete(): void {
    this.isButtonDisabled = true;
    this.isDeleteButtonDisabled = true;
    switch (this.mode) {
      case "E":
        this.closingOnOutsideClick = false;
        this.showCloseButton = false;
        this.dataHandler.deleteEntities(this.entitiesInfo).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => this.afterDelete()
        );

        break;
      case "P":
        this.selectedIds.forEach((id) => {
          this.dataHandler.deleteProperty(id).subscribe(
            () => this.share.upgradeGrid(),
            () => { },
            () => this.afterDelete()
          );
        });
        break;

      case "ET":
        this.dataHandler.deleteEntityType(this.selectedIds).subscribe(
          () => {
            this.share.upgradeGrid();
          },
          (error) =>
            console.log("HTTP Error during deliting entity types: ", error),
          () => this.afterDelete()
        );

        break;
      case "R":
        this.reportService.deleteReport(this.selectedIds).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => {
            this.afterDelete();
            this.reportSignalRService.invokeDeletedReport();
          }
        );

        break;
      case "S":
        this.scenarioServise.deleteScenarios(this.selectedIds).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => this.afterDelete()
        );

        break;
    }
  }

  public trackByFn(drawing): any {
    return drawing;
  }

  /// Method used to check whether the mode is included in the list of modes adapted for disabling delete button.
  public isModeIncluded(mode: string): boolean {
    if (mode) {
      let isAtLeastOneMode: boolean = this.includedDisableDeleteButtonModes.some(s => s.includes(mode));
      return isAtLeastOneMode;
    }
    return false;
  }

  private afterDelete(): void {
    this.share.deletePopupEmitter.emit();
    this.deletePopupVisible = false;
    this.closingOnOutsideClick = true;
    this.showCloseButton = true;
    this.share.emptySearchInput.emit();
  }

  private subscribeToReportEvents(): void {
    this.share.onReportCreationOrEdit.subscribe(() => {
      if (this.isModeIncluded(this.mode)) {
        this.isDeleteButtonDisabled = true;
      }
    });
    this.share.onReportCreationOrEditFinished.subscribe(() => {
      this.reportService.reportCreationOrEditOngoing(environment.customer).subscribe((value) => {
        if (this.isModeIncluded(this.mode)) {
          if (!value) {
            this.isDeleteButtonDisabled = false;
          }
        }
      });
    });
  }
}
