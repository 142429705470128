import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxPopupComponent } from 'devextreme-angular';
import { ReportService } from 'src/app/Services/report.service';
import { ReportSignalRService } from 'src/app/Services/signalR/report-signal-r.service';
import { NotifyRefreshReportsService } from 'src/app/Services/utility/notify-refresh-reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unlock-confirm-popup-info',
  templateUrl: './unlock-confirm-popup-info.component.html',
  styleUrls: ['./unlock-confirm-popup-info.component.scss']
})
export class UnlockConfirmPopupInfoComponent implements OnInit {
  public text: string = this.translate.instant("ConfirmUnlockReports");
  public confirm: string = this.translate.instant("Confirm");
  public container: ViewContainerRef;
  public signalRService: ReportSignalRService;
  public reportService: ReportService;
  public notifyRefreshReportService: NotifyRefreshReportsService;

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  public closeInfoPopup(): void {
    this.popup.instance.hide().then(() => this.container.clear());
  }

  public confirmUnlock(): void {
    this.reportService.unlockAllReports(environment.customer).subscribe((value: boolean) => {
      if (!value) {
        this.notifyRefreshReportService.displayRefreshingMessage(this.translate.instant("NoReportsToUnlock"));
      } else {
        this.signalRService.invokeUnlockedAllReports();
      }
      const infoHide: Promise<boolean> = this.popup.instance.hide();
      Promise.all([infoHide]).then(() => {
        this.container.clear();
      });
    });
  }

}
