import { TranslateService } from "@ngx-translate/core";
import { environment } from "./../../environments/environment";
import { AuthGuard } from "src/app/Guards/auth.guard";
import { Component, OnInit } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "@peoplepower/auth-library";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
})
export class ClientComponent implements OnInit {
  customer: string;
  sub: Subscription = new Subscription();
  public title: string;
  public locale: string;
  public menuActive: boolean = true;
  public topBarActive: boolean = true;
  public simpleFrameModeDisabled: boolean = true;

  public topbarDropdownJson = [
    {
      name: this._translate.instant("Logout"),
      event: "logout",
    },
  ];
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private authGuard: AuthGuard,
    private _translate: TranslateService,
  ) { }
  menuItemsJson = [
    {
      id: 1,
      name: "Space Management",
      displayName: this._translate.instant("SpaceManagement"),
      icon: "fas fa-cubes",
      isActive: true,
      link: [environment.customer, "space-management"],
    },
    {
      id: 2,
      name: "Editor",
      displayName: this._translate.instant("Editor"),
      icon: "fas fa-file-edit fw-pro",
      isActive: false,
      items: [
        {
          id: 2.1,
          name: "Type Properties",
          displayName: this._translate.instant("TypeProperties"),
          icon: "fas fa-container-storage fw-pro",
          isActive: false,
          link: [environment.customer, "editor", "type-property"],
        },
        {
          id: 2.2,
          name: "Entity Types",
          displayName: this._translate.instant("EntityTypes"),
          icon: "fas fa-dice-d6",
          isActive: false,
          link: [environment.customer, "editor", "entity-type"],
        },
      ],
    },
    {
      id: 3,
      name: "Reports",
      displayName: this._translate.instant("Reports"),
      icon: "fas fa-paste",
      isActive: false,
      link: [environment.customer, "reports"],
    },
    {
      id: 4,
      name: "Scenarios",
      displayName: this._translate.instant("Scenarios"),
      icon: "fas fa-scroll",
      isActive: false,
      link: [environment.customer, "scenarios"],
    },
  ];

  ngOnInit(): void {

    if (this.isWindowInIframe()) {
      this.disableMenuAndTopBar();
      return;
    }

    this.initTitle();
    this.authGuard.user.subscribe((user) => {
      if (user != undefined)
        if (user.displayName != null)
          this.customer = user.displayName + ` (${user.customerName})`;
        else this.customer = user.email + ` (${user.customerName})`;
    });

    this.sub = this.router.events.subscribe((ev: RouterEvent) => {
      this.changeTitle(this.router.url);
    });
  }
  getLocale() {
    const locale = localStorage.getItem("locale");
    return locale != null ? locale : "nl";
  }
  initTitle() {
    const curentUrlpath = window.location.pathname.split("/")[2];

    switch (curentUrlpath) {
      case "space-management":
        this.title = this._translate.instant("SpaceManagement");
        break;
      case "editor":
        if (window.location.pathname.split("/")[2] == "entity-type")
          this.title = this._translate.instant("EntityTypes");
        else this.title = this._translate.instant("TypeProperties");
        break;
      case "reports":
        this.title = this._translate.instant("Reports");
        break;
      case "scenarios":
        this.title = this._translate.instant("Scenarios");
        break;
    }
  }
  changeTitle(ev: string) {

    switch (ev.split("/")[2]) {
      case "space-management":
        this.title = this._translate.instant("SpaceManagement");
        break;
      case "editor":
        if (ev.split("/")[3] == "entity-type")
          this.title = this._translate.instant("EntityTypes");
        else this.title = this._translate.instant("TypeProperties");
        break;
      case "reports":
        this.title = this._translate.instant("Reports");
        break;
      case "scenarios":
        this.title = this._translate.instant("Scenarios");
        break;
    }
  }

  topBarDropDownEmitter(eventName: string): void {
    switch (eventName) {
      case "logout":
        this.auth.redirect("logout");
        break;

      default:
        break;
    }
  }
  onMenuItemClick(e) {
    this.router.navigate(e.detail);
  }

  //the values of parameter "locale" can not be empty string. For the current imlementation this is always either "en" or "nl"
  changeLanguage(locale: string) {
    localStorage.setItem("locale", locale);
    document.location.reload();
  }

  private disableMenuAndTopBar(): void {
      this.menuActive = false;
      this.topBarActive = false;
      this.simpleFrameModeDisabled = false;   
  }

  private isWindowInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
