import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { NotifierDuration } from 'src/app/Models/enums/notifier-duration';
import { NotifierType } from 'src/app/Models/enums/notifier-type';
import { NotifyPositionType } from '../../Models/enums/notify-position';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private minimumWidth: number = 150;
  private notifierHeight: number = 45;
  private widthMultiplier: number = 6;
  private maximumOverFlowLength: number = 80;

  types: string[] = ['error', 'info', 'success', 'warning'];
  predefinedPosition = NotifyPositionType.BottomRight;

  constructor() { }

  /// Displays a notified messsage.
  public displayNotifyMessage(text: string, notifierEnum: NotifierDuration, notifierType: NotifierType, position: NotifyPositionType = NotifyPositionType.BottomRight, maximumOverFlowLength: number = this.maximumOverFlowLength, direction: string = "up-push") {
    const duration: number = notifierEnum;
    const notifierWidth: number = this.calculateWidthBasedOnTextSize(text);
    var wrappedText = this.wrapText(text, maximumOverFlowLength);

    notify({
      message: wrappedText,
      height: this.notifierHeight,
      width: notifierWidth,
      minWidth: this.minimumWidth,
      type: this.types[notifierType],
      displayTime: duration,
      position: position,
      directon: direction
    });
  }

  /// Based on the text size recalculate the required width of the notifier.
  calculateWidthBasedOnTextSize(text: string) {
    var textLength = Math.max(this.minimumWidth, text.length * this.widthMultiplier);
    if (textLength > this.maximumOverFlowLength) {
      return this.maximumOverFlowLength * this.widthMultiplier;
    }
    return textLength;
  }

  public wrapText(text: string, maxLength: number): string[] {
    if (text.length <= maxLength) {
      return [text]; // No need to wrap if within the limit
    }

    const wrappedText: string[] = [];
    const truncatedText = text.substring(0, maxLength - 3); // Subtract 3 for the ellipses
    wrappedText.push(truncatedText + '...');

    return wrappedText;
  }

}
