import { Injectable, ViewContainerRef, QueryList } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';

@Injectable({
  providedIn: 'root'
})
export class ReportsPopupHandlerService {

  private popupList: Set<DxPopupComponent> = new Set<DxPopupComponent>();
  private parents: Set<ViewContainerRef> = new Set<ViewContainerRef>();

  constructor() { }

  /// Adds a popup element to the popuplist.s
  public addPopUp(popup: DxPopupComponent): void {
    this.popupList.add(popup);
  }

  public addParentContainers(parentContainer: ViewContainerRef): void {
    this.parents.add(parentContainer);
  }

  /// Removes a popup element from the popup list.
  public removePopup(popup: DxPopupComponent): void {
    if (this.popupList.has(popup)) {
      this.popupList.delete(popup);
    }
  }

  /// Closes all the opened popups from the list.
  public closeAllPopups(): void {
    for (let popup of this.popupList) {
      if (popup.instance) {
        popup.instance.hide();
      }
    }
    this.clearContainerRefs();
    this.clearSet();
  }

  /// Clears all the container refs associated with the popups.
  public clearContainerRefs(): void {
    if (this.parents && this.parents.size) {
      for (let parent of this.parents) {
        if (parent) {
          parent.clear();
        }
      }
    }
  }

  /// Clears the set of all popups.(opened and closed)
  public clearSet(): void {
    this.popupList.clear();
    this.parents.clear();
  }
}
