import { Directive, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: "[specialChars]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpecialCharactersValidatorDirective,
      multi: true,
    },
  ],
})
export class SpecialCharactersValidatorDirective implements Validator {

  validate(formControl: FormControl) {
    const regex = new RegExp(/[|<>]+/);
    if (regex.test(formControl.value)) {
      return { symbols: true };
    }
  }
}
