import { Component, ViewContainerRef } from "@angular/core";

@Component({
  selector: "app-info-delete-entity-type-popup",
  templateUrl: "./info-delete-entity-type-popup.component.html",
  styleUrls: ["./info-delete-entity-type-popup.component.scss"],
})
export class InfoDeleteEntityTypePopupComponent {
  public isVisible: boolean = false;
  public container: ViewContainerRef;
  public isForEntityType: boolean;
  constructor() {}

  public close(): void {
    this.isVisible = false;
    this.container.clear();
  }
}
