import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { shareReplay } from "rxjs/operators";
import { Store } from "../DATA/store";
import { TypeDefinition } from "../Models/typeDefinition";

@Injectable({
  providedIn: "root",
})
export class TypeDefinitionsResolverService
  implements Resolve<TypeDefinition[]>
{
  constructor(private _http: HttpClient, private _store: Store) {}

  public resolve(): Observable<TypeDefinition[]> {
    const typeDefinitions$: Observable<TypeDefinition[]> =
      this.getTypeDefinitions().pipe(shareReplay());

    typeDefinitions$.subscribe((typeDefinitions) => {
      this._store.typeDefinitions = typeDefinitions;
      this._store.typeDefintionsMap = new Map<number, string>(
        typeDefinitions.map(typeDef => [typeDef.Id, typeDef.Type])
      );
    });

    return typeDefinitions$;
  }

  public getTypeDefinitions(): Observable<TypeDefinition[]> {
    return this._http.get<TypeDefinition[]>(environment.backendControllerUrl + "/TypeDefinition/GetTypeDefinitions",{});
  }
}
