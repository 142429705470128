import { PermissionGuard } from "./Guards/permission.guard";
import { TypeDefinitionsResolverService } from "./Resolvers/type-definitions-resolver.service";
import { ClientComponent } from "./client/client.component";
import {
  RouterModule,
  UrlSerializer,
  PreloadAllModules,
} from "@angular/router";

import { ForbiddenComponent } from "./ForbiddenComponent/forbidden.component";

import { NgModule } from "@angular/core";
import { AuthGuard } from "./Guards/auth.guard";
import { EntityTypesResolver } from "./Resolvers/entity-types-resolver.service";
import { EntityTypePropertiesResolverService } from "./Resolvers/entity-type-properties-resolver.service";
import { EntityPropertiesResolverService } from "./Resolvers/properties-resolver.service";

import { QuadrantsResolverService } from "./Resolvers/quadrants-resolver.service";
import { ReportTypesResolverService } from "./Resolvers/report-types-resolver.service";
import { CustomConfigsResolverService } from "./Resolvers/customConfigs-resolver.service";

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([
      {
        path: ":client",
        component: ClientComponent,
        canActivate: [AuthGuard],
        resolve: {
          entityTypes: EntityTypesResolver,
          entityTypeProperties: EntityTypePropertiesResolverService,
          entityProperties: EntityPropertiesResolverService,
          customConfigs: CustomConfigsResolverService,
          quadrants: QuadrantsResolverService,
          reportTypes: ReportTypesResolverService,
          typeDefinitions: TypeDefinitionsResolverService,
        },
        children: [
          {
            path: "",
            pathMatch: "full",
          },
          // For edit and admin roles
          {
            path: "space-management",
            loadChildren: () =>
              import("./SpaceManagement/space-management.module").then(
                (m) => m.SpaceManagementModule
              ),
            canActivate: [PermissionGuard],
            data: { permissions: ["view", "edit", "manage", "admin"] },
          },
          // For admin role
          {
            path: "editor",
            loadChildren: () =>
              import("./Editor/editor.module").then((m) => m.EditorModule),
            canActivate: [PermissionGuard],
            data: { permissions: ["admin"] },
          },
          // For edit and admin roles
          {
            path: "scenarios",
            loadChildren: () =>
              import("./Scenarios/scenarios.module").then((m) => m.ScenariosModule),
            canActivate: [PermissionGuard],
            data: { permissions: ["manage", "admin"] },
          },
          // For edit and admin roles
          {
            path: "reports",
            loadChildren: () =>
              import("./Reports/reports.module").then((m) => m.ReportsModule),
            canActivate: [PermissionGuard],
            data: {
              permissions: ["view", "edit", "manage", "admin"]
            },
          },
          { path: "**", component: ForbiddenComponent },
        ],
      },
      { path: "", component: ForbiddenComponent },
      { path: "**", redirectTo: "" }], {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      paramsInheritanceStrategy: "always",
      relativeLinkResolution: "corrected",
      malformedUriErrorHandler: (
        error: URIError,
        urlSerializer: UrlSerializer,
        url: string
      ) => urlSerializer.parse("/page-not-found"),
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }