import { ReportType } from "./../Models/reportType";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { shareReplay } from "rxjs/operators";
import { Store } from "../DATA/store";

@Injectable({
  providedIn: "root",
})
export class ReportTypesResolverService implements Resolve<ReportType[]> {

  constructor(private _http: HttpClient, private _store: Store) { }

  public resolve(): Observable<ReportType[]> {
    const reportTypes$: Observable<ReportType[]> = this.getReportTypes().pipe(
      shareReplay()
    );

    reportTypes$.subscribe((reportTypes) => {
      this._store.reportTypes = reportTypes;
      reportTypes.forEach((reportType) => {
        this._store.reportTypeNameByReportTypeId.set(
          reportType.Id,
          reportType.Name
        );
      });
    });

    return reportTypes$;
  }

  public getReportTypes(): Observable<ReportType[]> {
    return this._http.get<ReportType[]>(
      environment.backendControllerUrl + "/ReportType/GetReportTypes"
    );
  }
}
