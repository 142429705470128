import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ReportType } from "../Models/reportType";

@Injectable({
  providedIn: "root",
})
export class ReportTypesService {

  constructor(private http: HttpClient) {}

  public getReportTypes(): Observable<ReportType[]> {
    return this.http.get<ReportType[]>(
      environment.backendControllerUrl + "/ReportType/GetReportTypes"
    );
  }
}
