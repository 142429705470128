import { Component, OnInit } from '@angular/core';
import { PopupInfoComponent } from '../popup-info/popup-info.component';
import { TranslateService } from '@ngx-translate/core';
import { ShareService } from 'src/app/Services/share.service';
import { ReportsPopupHandlerService } from 'src/app/Services/utility/reports-popup-handler.service';

@Component({
  selector: 'app-report-popup-info',
  templateUrl: './report-popup-info.component.html',
  styleUrls: ['./report-popup-info.component.scss']
})
export class ReportPopupInfoComponent extends PopupInfoComponent implements OnInit {

  public shouldEmitEvents: boolean = true;
  public isEditMode: boolean = false;
  constructor(private translateService: TranslateService, public reportPopupHandler: ReportsPopupHandlerService,
    private shareService: ShareService) { super(translateService); }

  ngOnInit(): void {
  }

  onPopUpInit(e: any) {
    if (this.isEditMode) {
      this.reportPopupHandler.addPopUp(this.popup);
    }
  }

  public closeInfoPopup(): void {
    super.closeInfoPopup();
    this.popup.instance = null;
  }

  public closeAllPopups(): void {
    if (this.shouldEmitEvents) {
      this.shareService.emitReportInfoPopupClosed();
    }
    super.closeAllPopups();
  }

}
