import { CustomerRole } from "./../types/customerRole";
import { UserPermission } from "./../types/userPermission";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";

import { map, switchMap } from "rxjs/operators";
import { Store } from "../DATA/store";
import { AuthenticationService, ResponseModel, User } from "@peoplepower/auth-library";
import { CustomersService } from "../Services/customers.service";
import { ReportSignalRService } from "../Services/signalR/report-signal-r.service";
import { ConfigurationService } from "../Services/configuration.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  response = new ResponseModel();

  customer: BehaviorSubject<string> = new BehaviorSubject<string>("");
  user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(
    private authService: AuthenticationService,
    private _store: Store,
    private _customersService: CustomersService,
    private _reportSignalR: ReportSignalRService,
    private _configService: ConfigurationService,
    private _router: Router,
  ) {

  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> {
    const isInIframe = this._store.isWindowInIframe();
    return this._configService.isApplicationAllowedOutsideWebviewer().pipe(
      switchMap((response) => {
        //checks if we have access to Space outside AsBuilt platform
        if (response == false && isInIframe==false) {
          this._router.navigate(['']);
          return of(false)
        }
        else {
          let currentCustomer = activatedRoute.params.client;
          return (this._customersService.getCustomers()).pipe(
            switchMap((customers: string[]) => {
              if (!customers.includes(currentCustomer)) {
                this._router.navigate(['']);
                return of(false);
              }
              return this.authService.getUser().pipe(
                map((response) => {
                  const isUserExists = response?.user === null ? false : true;
                  if (isUserExists) {
                    this._store.role = this.setCustomerRole(response.user.permissions);
                    this._store.permissions = response.user.permissions.filter(
                      (permission) => this.customerRoleFilter(permission)
                    ) as UserPermission[];
                    this._store.user = response.user;
                  }
                  this.response = response;
                  if (this.response && this.response.actionRequired) {
                    // login / logout
                    this._reportSignalR.invokeDisconnectingEvent();
                    this.authService.redirect(this.response.actionRequired);
                    return false;
                  }
                  this.customer.next(this.response?.user.customerName);
                  this.user.next(this.response?.user);

                  return true;
                })
              );
            }
            ));
        }
      }))
    }
  private customerRoleFilter(role: string): role is UserPermission {
    return ["admin", "view","space_manage_4","edit"].some((item) => item === role);
  }

  private setCustomerRole(permissions: string[]): CustomerRole {
    if (permissions.includes("admin")) return "admin";
    if (permissions.includes("space_manage_4")) return "manage";
    if (permissions.includes("edit")) return "edit";
    if (permissions.includes("view")) return "view";
  }
}
